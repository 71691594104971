// src/components/CarouselComponent.js
import React from 'react';
import { Carousel } from 'react-bootstrap';
import './CarouselComponent.css';

function CarouselComponent() {

  const photos = [
    "IMG_01.JPG",
    "IMG_02.JPG",
    "IMG_03.JPG",
    // Add more paths as needed
  ];

  console.log("PUBLIC_URL:", process.env.PUBLIC_URL);



  return (
    <div className="carousel-container">
      <Carousel controls={false} indicators={false} interval={10000} fade>
        {photos.map((photo, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={`./assets/photos/${photo}`}
              alt={`Slide ${index}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
