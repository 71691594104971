// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ImageModal.css */
.custom-modal .modal-dialog {
    max-width: 80%;  /* Adjust this value as needed */
  }
  
  .custom-modal .modal-body {
    position: relative;
    padding: 0;
  }
  
  .custom-modal .modal-body img {
    width: 100%;
  }
  
  .position-absolute {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .start-0 {
    left: 10px;
  }
  
  .end-0 {
    right: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ImageModal/ImageModal.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,cAAc,GAAG,gCAAgC;EACnD;;EAEA;IACE,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,QAAQ;IACR,2BAA2B;EAC7B;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb","sourcesContent":["/* src/components/ImageModal.css */\n.custom-modal .modal-dialog {\n    max-width: 80%;  /* Adjust this value as needed */\n  }\n  \n  .custom-modal .modal-body {\n    position: relative;\n    padding: 0;\n  }\n  \n  .custom-modal .modal-body img {\n    width: 100%;\n  }\n  \n  .position-absolute {\n    top: 50%;\n    transform: translateY(-50%);\n  }\n  \n  .start-0 {\n    left: 10px;\n  }\n  \n  .end-0 {\n    right: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
