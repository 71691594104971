import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';
import './Contact.css';

function Contact() {
  return (
    <div style={{ margin: '20px' }}>
      <h2>Contact Us</h2>
      <p>Use the form below to contact us with general inquiries. You can also inquire about open positions.</p>
      <ContactForm />
    </div>
  )
}

export default Contact;
