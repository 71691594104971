import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Port from './pages/Port/Port';
import Faqs from './pages/Faqs/Faqs';
import Gallery from './pages/Gallery/Gallery';
import NotFound from './pages/NotFound';
// import News from './pages/News/News';
import NavigationBar from './components/NavBar/Navbar';
import CarouselComponent from './components/Carousel/CarouselComponent';
import Footer from './components/Footer/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <NavigationBar />
        <CarouselComponent />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/port" element={<Port />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="*" element={<NotFound />} />  Catch-all route for undefined paths */}
          {/* <Route path="/news" element={<News />} /> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
