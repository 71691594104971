const landmarks = [
    { id: 1, name: 'B Street Cruise Ship Terminal', position: { lat: 32.7174, lng: -117.1745 }, description: "Located in the heart of downtown San Diego, the B Street Cruise Ship Terminal serves as a primary gateway for cruise passengers. It accommodates some of the world's largest cruise ships, offering state-of-the-art facilities and amenities to enhance the passenger experience. The terminal is a vital link in promoting tourism and supporting the local economy." },
    { id: 2, name: 'Tenth Avenue Marine Terminal', position: { lat: 32.6974, lng: -117.1515 }, description: "Situated near downtown San Diego, the Tenth Avenue Marine Terminal handles a diverse range of cargo, including refrigerated goods, breakbulk, and project cargo. It is equipped with advanced infrastructure to support efficient cargo operations and is a critical asset for regional trade and commerce." },
    { id: 3, name: 'National City Marine Terminal', position: { lat: 32.6653, lng: -117.1153 }, description: "Located in the city of National City, this terminal specializes in the import and export of vehicles, lumber, and other bulk commodities. It features extensive storage facilities and modern equipment to ensure the smooth handling of large volumes of cargo, playing a crucial role in the automotive supply chain." },
    { id: 4, name: 'Continental Maritime', position: { lat: 32.6955, lng: -117.1470 }, description: "Continental Maritime of San Diego provides comprehensive ship repair and maintenance services. It supports various naval and commercial vessels, offering hull repairs and system upgrades, thereby contributing to the operational readiness of the maritime fleet." },
    { id: 5, name: 'BAE Systems San Diego Ship Repair', position: { lat: 32.6931, lng: -117.1439 }, description: "BAE Systems operates one of the largest shipyards on the West Coast, specializing in the repair, maintenance, and modernization of military and commercial ships. The facility is equipped with multiple dry docks and advanced technology to handle complex maritime projects." },
    { id: 6, name: 'General Dynamics NASSCO', position: { lat: 32.6903, lng: -117.1381 }, description: "General Dynamics NASSCO is renowned for its shipbuilding and repair capabilities. It constructs new vessels and provides maintenance and repair services for both commercial and government clients. NASSCO is a key player in supporting the U.S. Navy’s fleet with high-quality shipbuilding services." },
    { id: 7, name: 'Austal USA', position: { lat: 32.6587 , lng: -117.1165 }, description: "Austal USA focuses on the design and construction of advanced aluminum vessels, including high-speed ferries and military ships. The shipyard employs cutting-edge technology and skilled labor to deliver innovative maritime solutions, enhancing the region’s shipbuilding industry." },
    { id: 8, name: 'Marine Group Boat Works', position: { lat: 32.6306, lng: -117.1072 }, description: "Located in Chula Vista, Marine Group Boat Works specializes in the construction, repair, and refit of yachts, commercial vessels, and military craft. The facility is equipped with state-of-the-art tools and skilled craftsmen, ensuring high-quality services for a wide range of marine vessels." }
  ];
  
  export default landmarks;
  
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2824.5928926720358!2d-117.12099977113112!3d32.65875318475248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d9520c795fd4cd%3A0x384882c3115f4d2b!2sAustal%20USA!5e0!3m2!1sen!2sus!4v1724980718267!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>