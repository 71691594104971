// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-content {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: white;
}

.text-left {
  text-align: left;
}

.card {
  --bs-card-border-color: #ff0000;
  --bs-card-border-width: 3px;
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,2BAA2B;AAC7B","sourcesContent":[".about-content {\n  position: relative;\n  z-index: 1;\n  padding: 20px;\n  color: white;\n}\n\n.text-left {\n  text-align: left;\n}\n\n.card {\n  --bs-card-border-color: #ff0000;\n  --bs-card-border-width: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
