// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Home.css */
.home-content {
    position: relative;
    z-index: 1;
    padding: 20px;
    color: white; /* Change text color to ensure visibility */
  }

  .imgRow {
    background: white;
  }

  .imgCard {
    border: 0rem;
    margin: 0 auto;
  }

  .imgCol {
    padding: 0rem;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,YAAY,EAAE,2CAA2C;EAC3D;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,aAAa;EACf","sourcesContent":["/* src/Home.css */\n.home-content {\n    position: relative;\n    z-index: 1;\n    padding: 20px;\n    color: white; /* Change text color to ensure visibility */\n  }\n\n  .imgRow {\n    background: white;\n  }\n\n  .imgCard {\n    border: 0rem;\n    margin: 0 auto;\n  }\n\n  .imgCol {\n    padding: 0rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
