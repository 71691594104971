import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Faqs.css';

function Faqs() {
  return (
    <div>
    <Container className="faq-content">
      <h2>Frequently Asked Questions</h2>
      </Container>
    </div>
  )
}

export default Faqs;
