import React, { useState }  from 'react';
import './Gallery.css';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import ImageModal from '../../components/ImageModal/ImageModal';

const photos = [
    "IMG_01.JPG",
    "IMG_02.JPG",
    "IMG_03.JPG",
    // Add more paths as needed
];

const Gallery = () => {
    const [showModal, setShowModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleShow = (index) => {
        setCurrentIndex(index);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? photos.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === photos.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Container className="mt-5">
            <h2>Photo Gallery</h2>
            <Row>
                {photos.map((photo, index) => (
                    <Col key={index} sm={6} md={4} lg={3} className="mb-4">
                        <Image src={`${process.env.PUBLIC_URL}/assets/photos/${photo}`} thumbnail onClick={() => handleShow(index)}
                        />
                    </Col>
                ))}
            </Row>
            <ImageModal
                show={showModal}
                handleClose={handleClose}
                imageSrc={`${process.env.PUBLIC_URL}/assets/photos/${photos[currentIndex]}`}
                handlePrev={handlePrev}
                handleNext={handleNext}
            />
        </Container>
    );
};

export default Gallery;