// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-thumbnail {
    max-width: 100%;
    height: auto;
  }

`, "",{"version":3,"sources":["webpack://./src/pages/Gallery/Gallery.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;EACd","sourcesContent":[".img-thumbnail {\n    max-width: 100%;\n    height: auto;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
