import React from 'react';
import { Container, Row, Col, Card, Image, CardGroup } from 'react-bootstrap';
import './About.css';

function About() {
  return (
    <div>
      <Container className="about-content">
        <Row className="justify-content-center" style={{ background: '#282763', 'margin-bottom': '30px' }}>
          <Col md={6}>
            <Card className="imgCard" style={{ background: '#282763', color: 'white', margin: '20px' }}>
              <Card.Body>
                <Card.Title>What is a Harbor Pilot?</Card.Title>
                <Card.Text className="text-left">
                  <p>A marine harbor pilot is a specialized mariner with extensive knowledge and experience in
                    navigating local waters. Their primary role is to guide ships through congested or challenging areas, such
                    as ports, harbors, and coastal waters. Harbor pilots are experts in local maritime conditions, including
                    tides, currents, weather patterns, and underwater hazards. When a ship approaches a port, the harbor
                    pilot typically boards the vessel, often using a pilot boat, and takes over navigational duties from the
                    ship’s captain. The pilot’s in-depth understanding of the local waters ensures the ship can safely
                    navigate to its berth, avoiding obstacles and ensuring compliance with local maritime regulations.
                    Harbor pilots are essential for maintaining safety and efficiency in busy maritime hubs, and their work
                    helps prevent accidents, environmental damage, and delays in port operations. They undergo rigorous
                    training and certification processes to ensure they have the skills and knowledge required for their
                    critical role.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex align-items-center" md={6}>
            <Image src="./assets/photos/pilotboat1.jpg" style={{ width: '400px' }} />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Image src="./assets/photos/P2190037.jpg" style={{ width: '600px' }} />
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Card className="imgCard">
              <Card.Body>
                <Card.Title>Our Mandate</Card.Title>
                <Card.Text>
                  <p>The Port of San Diego is a bustling maritime hub, shared by the vibrant community of our region. It
                    accommodates a diverse array of vessels, including private sailboats, kayaks, jet skis, fishing skiffs, motor
                    yachts, paddleboards, harbor excursion vessels, ferries, tug boats, towing barges, naval destroyers, high-
                    speed security boats, Navy aircraft carriers, Coast Guard cutters, sport fishing fleets, bait boats, sailing
                    regattas, lobster boats, container ships, cruise ships, car carriers, mega yachts, dolphin training vessels,
                    harbor police boats, oil tankers, research vessels, and nuclear submarines.</p>
                  <p>The sheer volume and variety of traffic on the Bay is legendary, demanding our pilots&#39;
                    undivided and independent focus to prevent groundings and collisions. With numerous lives
                    and the marine environment&#39;s health at stake, we understand the gravity of our responsibility to protect both at all times.</p>
                  <p>Therefore, we are steadfastly committed to excellence in our pilot service for the Port of San
                    Diego. Our mandate is to facilitate maritime trade and commerce efficiently while maintaining an
                    unwavering focus on safety. We pledge to uphold the highest standards of professionalism and
                    vigilance, ensuring the safe passage of all vessels within our waters and contributing to the prosperity
                    and security of our community.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ background: '#282763' }}>
          <Col>
            <Card className="imgCard" style={{ background: '#282763', color: 'white', margin: '20px' }}>
              <Card.Body>
                <Card.Title>Our Operations</Card.Title>
                <Card.Text>
                  <p>The San Diego Bay Pilots Association (SDBPA) operates with a commitment to safety, precision, and
                    efficiency, ensuring the secure navigation of vessels within San Diego Bay. Our operation is
                    headquartered on Shelter Island, where we maintain a dedicated pilot boat that travels approximately
                    six miles to meet inbound ships near the San Diego Sea Buoy.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ background: '#282763', padding: '20px' }}>
          <CardGroup>
            <Card>
              <Card.Body>
                <Card.Title>Boarding Operations:</Card.Title>
                <Card.Text>
                  Upon reaching the sea buoy, the pilot boat matches the speed and heading of the incoming vessel. This
                  synchronization is crucial as the pilot times their jump-step onto the rope ladder suspended from the
                  ship. Boarding is inherently dangerous, with many pilots experiencing serious injuries at some point in
                  their careers. Globally, one pilot in twenty loses their lives on the job.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Onboard Procedure:</Card.Title>
                <Card.Text>
                  Once aboard, the pilot is escorted to the ship’s wheelhouse to meet with the Captain. This meeting
                  involves a critical exchange of information where the Captain briefs the pilot on any deficiencies and
                  provides necessary paperwork describing the vessel. In turn, the pilot outlines the voyage plan and local
                  conditions.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Navigation and Control:</Card.Title>
                <Card.Text>
                  <p>
                    Taking the conn of the ship, the pilot assumes control, executing all engine and helm commands. The
                    pilot also manages ship-to-ship and ship-to-shore communications, ensuring clear and precise directives.
                    When tugboats are required, the pilot directs their operations to safely navigate and dock the ship at its
                    assigned berth.</p>
                  <p>
                    The SDBPA’s meticulous and highly skilled operations underscore our dedication to protecting lives,
                    safeguarding the marine environment, and facilitating the efficient and secure movement of maritime
                    traffic within San Diego Bay.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Row>
      </Container >
    </div >
  )
}

export default About;
