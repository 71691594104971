// src/Navbar.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavBar.css';

function NavigationBar() {
    return (
        <Navbar variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src="/assets/logo/sdbaypilots_blackoutline.png"
                        width="100"
                        height="100"
                        className="d-inline-block align-top"
                        alt="San Diego Bay Pilots Association"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/about">
                            <Nav.Link>About Us</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/port">
                            <Nav.Link>Our Port</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/gallery">
                            <Nav.Link>Gallery</Nav.Link>
                        </LinkContainer>
                        {/* <LinkContainer to="/faq">
                            <Nav.Link>FAQs</Nav.Link>
                        </LinkContainer> */}
                        {/* <LinkContainer to="/news">
                            <Nav.Link>News</Nav.Link>
                        </LinkContainer> */}
                        <LinkContainer to="/contact">
                            <Nav.Link>Contact</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;
