import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ImageModal.css';

const ImageModal = ({ show, handleClose, imageSrc, handlePrev, handleNext }) => {
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal" centered>
      <Modal.Header>
        <Button variant="light" onClick={handleClose} style={{ marginLeft: 'auto' }}>X</Button>
      </Modal.Header>
      <Modal.Body className="text-center">
        <img src={imageSrc} alt="Gallery" className="img-fluid" />
        <Button variant="light" className="position-absolute start-0" onClick={handlePrev}>&lt;</Button>
        <Button variant="light" className="position-absolute end-0" onClick={handleNext}>&gt;</Button>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;