import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <Container className="mt-5">
            <Row>
                <Col className="text-center">
                    <Card>
                        <Card.Body>
                            <Card.Title>404 - Page Not Found</Card.Title>
                            <p>Sorry, the page you are looking for does not exist.</p>
                            <Link to="/">
                                <Button primary>Go Home</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default NotFound;
