// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/CarouselComponent.css */
/* .carousel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  } */
  
  .carousel-item img {
    object-fit: cover;
    width: 100vw;
    height: 50vh;
  }

  .carousel-container {
    position: relative;
  }
  
  .carousel-container .carousel {
    height: 50vh;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Carousel/CarouselComponent.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;;;;;;;KAOK;;EAEH;IACE,iBAAiB;IACjB,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd","sourcesContent":["/* src/components/CarouselComponent.css */\n/* .carousel {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n  } */\n  \n  .carousel-item img {\n    object-fit: cover;\n    width: 100vw;\n    height: 50vh;\n  }\n\n  .carousel-container {\n    position: relative;\n  }\n  \n  .carousel-container .carousel {\n    height: 50vh;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
