// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-content {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: black;
}

.left-justify {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/Contact.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".contact-content {\n  position: relative;\n  z-index: 1;\n  padding: 20px;\n  color: black;\n}\n\n.left-justify {\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
