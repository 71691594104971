import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import './Links.css';

function Links() {
  return (
    <div>
    <Container className="links-content">
      <h2>Frequently Asked Questions</h2>
      <Link 
        to="/assets/documents/Port of SD Tariff 2024-Pilotage.pdf" 
        download 
        target='blank'
      >Tariffs</Link>
      <Link 
        to="https://www.ics-shipping.org/wp-content/uploads/2022/01/Shipping-Industry-Guidance-on-Pilot-Transfer-Arrangements-v3.pdf" 
        download 
        target='blank'
      >Shipping Industry Guidance on Pilot Transfer Arrangements</Link>
      </Container>
    </div>
  )
}

export default Links;
