// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    /* position: fixed;
    bottom: 0; */
    width: 100%;
    padding: 10px 0;
    background: #282763;
    border-top: 3px solid #92999E;
  }
  
  .contact-info {
    text-align: left;
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI;gBACY;IACZ,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,6BAA6B;EAC/B;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".footer {\n    /* position: fixed;\n    bottom: 0; */\n    width: 100%;\n    padding: 10px 0;\n    background: #282763;\n    border-top: 3px solid #92999E;\n  }\n  \n  .contact-info {\n    text-align: left;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
