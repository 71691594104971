// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background: #282763;
    border-bottom: 3px solid #282763;
}

.nav-link {
    color: #E0E0E0;
}

.nav-link:hover {
    color: #FFFFFF;
}

a.nav-link.active {
    color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".navbar {\n    background: #282763;\n    border-bottom: 3px solid #282763;\n}\n\n.nav-link {\n    color: #E0E0E0;\n}\n\n.nav-link:hover {\n    color: #FFFFFF;\n}\n\na.nav-link.active {\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
