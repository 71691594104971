import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from '../../components/ContactForm/ContactForm';
import './Contact.css';

function Contact() {
  return (
    <Container id="contactPage" className="contact-content">
      <Row>
        <Col>
          <h2>Contact Us</h2>
        </Col>
      </Row>
      <Row>
        <Col className="left-justify">
          To order a harbor pilot in San Diego, contact our 24/7 dispatch line and be ready to provide the following vessel details:
          <ul>
            <li>
              Vessel name
            </li>
            <li>
              Vessel type and dimensions
            </li>
            <li>
              Estimated time of arrival (ETA) or departure time
            </li>
            <li>
              Vessel location or destination in San Diego
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Use the form below to contact us with general inquiries. You can also inquire about open positions.</p>
          <ContactForm />
        </Col>
      </Row>

    </Container>
  )
}

export default Contact;
