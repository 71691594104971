// src/components/MapComponent.js
import React from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import landmarks from '../../data/landmarks';

const containerStyle = {
  width: '100%',
  height: '450px'
};

const center = {
  lat: 32.7157,
  lng: -117.1611
};

const MapComponent = ({ onMarkerClick }) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyARFAEHivLgpx8cgthSLqOGDaoF8LIudvU">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12}>
        {landmarks.map((landmark) => (
          <MarkerF
            key={landmark.id}
            position={landmark.position}
            title={landmark.name}
            onClick={() => onMarkerClick(landmark)}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
